import React, { useEffect, useState } from "react"
import LayoutShop from "../components/layoutShop"
import { useShoppingCart } from "use-shopping-cart"
import { CartItems } from "../components/cart"
import tw, { styled } from "twin.macro"
import { FiShoppingCart } from "react-icons/fi"
import LogRocket from 'logrocket';
LogRocket.init('ziiqiw/studio-conscious');

const Header = styled.section`
  ${tw`mt-20 text-center`}
`

const CartButton = styled.div`
  position: relative;
  @media (min-width: 1024px) {
    position: fixed;
    top: 29px;
    right: 30px;
  }
  z-index: 6000;
  button {
    &:hover {
      cursor: pointer;
    }
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }
`

const Cancelled = ({ location }) => {
  const { clearCart } = useShoppingCart()

  const [close, setClose] = useState(true)

  const toggleCart = () => {
    setClose(!close)
  }
  const showCart = () => {
    setClose(true)
  }

  // useEffect(() => {
  //   showCart()
  // }, [])

  return (
    <LayoutShop location={location}>
      <Header>
        <h1>Cancelled</h1>
      </Header>
      <CartItems close={close} location={location} />
      <CartButton>
        <button onClick={toggleCart}>
          <FiShoppingCart size={25} color="#b9b7b8" />
        </button>
      </CartButton>
    </LayoutShop>
  )
}

const WithLayout = Component => {
  return props => (
    <>
      <LayoutShop location={props.location}>
        <Component {...props} />
      </LayoutShop>
    </>
  )
}

export default WithLayout(Cancelled)
